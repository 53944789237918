import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useGetSearchResultsQuery} from "../../app/service/any-pagesAPI";

function ShowResults({pageType}) {
    const fieldComponentName = `${pageType}_field_component`;
    const graduationLevelField = `${pageType}GraduationLevel`;
    const resultNameField = `${pageType}Name`;
    const resultPath = pageType;

    const resultsTitle = "Results";
    const noResultsTitle = "No Results";

    // const sortType = "University";
    // const orderType = "Highest to lowest";

    const navigate = useNavigate();

    const fieldId = useSelector(state => state.filteringSubjectsAndPrograms.fieldSelected);
    const graduationLevel = useSelector(state => state.filteringSubjectsAndPrograms.graduationLevelSelected);
    const universityId = useSelector(state => state.filteringSubjectsAndPrograms.universitySelected);

    const handleResultClick = (universityId, id) => {
        navigate(`/universities/${universityId}/${resultPath}/${id}`);
    };

    const { data: searchResult, isLoading } = useGetSearchResultsQuery({
        pageType,
        universityId,
        graduationLevel,
        fieldId,
        fieldComponentName,
        graduationLevelField,
    });

    return (
        <div className="primaryPageSizing">
            <h2 className="titleTextSecondary">{resultsTitle}</h2>

            {!isLoading && searchResult.meta.pagination.total > 0 ? (
                <div>
                    {Array.from(new Set(searchResult.data
                        .filter(result => result.attributes.university_page?.data?.id) // Filter out results without university_page
                        .map(result => result.attributes.university_page.data.id)
                    )).map((universityId, index) => {
                        const universityResults = searchResult.data.filter(result => result.attributes.university_page?.data?.id === universityId);
                        const universityName = universityResults[0]?.attributes?.university_page?.data?.attributes?.universityName;

                        return (
                            <div key={index}>
                                <div className="grid grid-cols-1 p-0 m-0">
                                    <div
                                        className="text-sc-red dark:text-gray-200 my-1 relative text-center text-3xl pt-5 font-bold">
                                        {universityName}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:grid sm:grid-cols-4 2xl:grid-cols-6">
                                    {universityResults && universityResults.map((result, resultIndex) => (
                                        <div
                                            key={resultIndex}
                                            className="fieldStyling"
                                            onClick={() => handleResultClick(universityId, result.id)}
                                        >
                                            {result.attributes[resultNameField]}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="grid grid-cols-1 p-0 m-0">
                    <div
                        className="text-sc-red dark:text-gray-200 my-1 relative text-center text-3xl pt-5 font-bold">
                        {noResultsTitle}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ShowResults;