import React, { useEffect } from 'react';
import ChevronLight from '../../images/icons/ChevronLeft.png';
import ChevronDark from '../../images/icons/ChevronLeftDark.png';

function PreviewDetailView({ uniName, itemName, itemGrad, itemDesc, onClose }) {

    // Prevent background scrolling when this view is open
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="relative bg-white dark:bg-gray-900 w-full h-[80vh] rounded-lg px-6 mx-6">
                {/* Close button */}
                <button 
                    onClick={onClose}
                    className="absolute z-10 text-5xl top-4 right-4 text-gray-700 dark:text-gray-200"
                >
                    ✖
                </button>

                <div className="flex items-center mt-2">
                    <img className="h-6 sm:h-10 cursor-pointer mr-1 block dark:hidden" src={ChevronLight} alt="Back arrow" />
                    <img className="h-6 sm:h-10 cursor-pointer mr-1 hidden dark:block" src={ChevronDark} alt="Back arrow" />
                    <h1 className="w-full text-left text-xl sm:text-3xl font-bold ml-2 text-sc-red dark:text-gray-300">{uniName}</h1>
                </div>

                <div className="mt-4">
                    <h3 className="text-gray-400 text-xl sm:text-3xl font-bold">{itemName}</h3>
                    <h4 className="text-gray-500 text-base sm:text-2xl">{itemGrad}</h4>
                    <p className="text-gray-900 dark:text-gray-200 text-sm font-normal mt-4">{itemDesc}</p>
                </div>
            </div>
        </div>
    );
}

export default PreviewDetailView;