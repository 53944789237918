import ChevronLight from '../../images/icons/ChevronLeft.png';
import ChevronDark from '../../images/icons/ChevronLeftDark.png';
import { useNavigate, useParams } from 'react-router-dom';

import InteractionArea from '../../components/Posts/InteractionArea';
import { useEffect, useState } from "react";
import { BASE_URL } from "../../API";

function DetailView(args) {
    const { idUniversity, idDetail } = useParams();
    const navigate = useNavigate();
    const [detail, setDetail] = useState({});
    const [university, setUniversity] = useState({});
    const [type, setType] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                if (type) {
                    const response = await fetch(
                        `${BASE_URL}/api/${type}-pages/${idDetail}
                    ?fields[0]=${type}Name
                    &fields[1]=${type}Description
                    &fields[2]=${type}GraduationLevel
                    `);
                    const responseData = await response.json();

                    setDetail(responseData.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [idDetail, type]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages/${idUniversity}?fields[0]=universityName
                    `);
                const responseData = await response.json();

                setUniversity(responseData.data.attributes);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [idUniversity]);

    useEffect(() => {
        setType(window.location.pathname.includes('/program/') ? 'program' : 'subject');
    }, []);

    const handleBack = () => {
        navigate(`/universities/${idUniversity}`);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex-grow" style={{ margin: '0', width: '100vw', position: 'relative' }}>
                <div style={{ marginLeft: '2%', marginRight: '1%' }}>
                    {university && (
                        <div className="flex items-center mt-2" onClick={handleBack}>
                            <img className="h-6 sm:h-10 cursor-pointer mr-1 block dark:hidden" src={ChevronLight} alt="Back arrow" />
                            <img className="h-6 sm:h-10 cursor-pointer mr-1 hidden dark:block" src={ChevronDark} alt="Back arrow" />
                            <h1 className="w-full text-left text-xl sm:text-3xl font-bold ml-2 text-sc-red dark:text-gray-300">{university.universityName}</h1>
                        </div>
                    )}
    
                    {detail.attributes && (
                        <>
                            <h3 className="text-gray-400 text-xl sm:text-3xl mt-4 font-bold">{detail.attributes[`${type}Name`]}</h3>
                            <h4 className="text-gray-500 text-base sm:text-2xl">{detail.attributes[`${type}GraduationLevel`]}</h4>
                            <p className="text-gray-900 dark:text-gray-200 text-sm font-normal mt-4">{detail.attributes[`${type}Description`]}</p>
                        </>
                    )}
                </div>
                {detail.attributes && (
                    <InteractionArea interactionName={`${type}-pages/${detail.id}`} />
                )}
            </div>
        </div>
    );
}

export default DetailView;
