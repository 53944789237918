import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "../../API";

export const iconsAPI = createApi({
    reducerPath: 'iconsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
    }),
    endpoints: (builder) => ({
        getAllIcons: builder.query({
            query: () => '/icons/?populate[image][fields][0]=formats' +
                '&pagination[pageSize]=100',
        }),
        getIconById: builder.query({
            query: (id) => `/icons/${id}?populate[image][fields][0]=formats`,
        }),
    }),
});

export const { useGetAllIconsQuery, useGetIconByIdQuery } = iconsAPI;

