import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {API} from "../../API";

export const addPostAPI = createApi({
    reducerPath: 'addPostAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.jwt
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        },
    }),
    endpoints: (builder) => ({
        addPost: builder.mutation({
            query: (postData) => ({
                url: `/${postData.postTypeSelect}`,
                method: "POST",
                body: { data: postData.newPostData },
            }),
        }),
    }),
})

export const { useAddPostMutation } = addPostAPI
