import PageHeading from "../../components/ProgramsAndSubjectsLoad/PageHeading";
import TermsAndConditionsPic from "../../images/icons/termsAndConditions.png";

function TermsAndConditions() {

    return (
        <>
            <PageHeading pageName="Terms and Conditions" icon={TermsAndConditionsPic}/>
            <p className="ml-10 text-xl">Content goes here</p>
        </>
    )
}

export default TermsAndConditions;