import PageHeading from "../../components/ProgramsAndSubjectsLoad/PageHeading";
import Contact from "../../images/icons/contact.png";

function ContactUs() {

    return (
        <>
            <PageHeading pageName="Contact Us" icon={Contact}/>
            <p className="ml-10 text-xl">Content goes here</p>
        </>
    )
}

export default ContactUs;