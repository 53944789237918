import PageHeading from "../../components/ProgramsAndSubjectsLoad/PageHeading";
import About from "../../images/icons/about.png";

// Import Memojis
import Jack from "../../images/memoji/Jack.png";
import Sithum from "../../images/memoji/Sith.png";
import Will from "../../images/memoji/Will.png";
import Izak from "../../images/memoji/Izak.png";
import June from "../../images/memoji/June.png";
import WangLin from "../../images/memoji/Max.png";

function AboutUs() {

    return (
        <>
            <PageHeading pageName="About Us" icon={About}/>

            <div>
                <h1 className="sm:ml-10 text-3xl font-bold mx-auto text-gray-500 dark:text-gray-200">The Development Team</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 w-full sm:w-3/5">
                    <PersonalTable img={Jack} name="Jack" gradient="bg-gradient-to-r from-blue-600 to-emerald-400" desc="Front End Sofware Engineer" />
                    <PersonalTable img={Sithum} name="Sithum" gradient="bg-gradient-to-r from-red-600 to-green-400" desc="Full Stack Developer" />
                    <PersonalTable img={Will} name="Will" gradient="bg-gradient-to-r from-blue-600 to-indigo-400" desc="Back End Software Engineer & Software Tester" />
                    <PersonalTable img={Izak} name="Izak" gradient="bg-gradient-to-r from-sky-600 to-rose-300" desc="Front End Designer" />
                    <PersonalTable img={June} name="June" gradient="bg-gradient-to-r from-fuchsia-600 to-rose-400" desc="Full Stack Software Engineer" />
                    <PersonalTable img={WangLin} name="WangLin" gradient="bg-gradient-to-r from-cyan-700 to-blue-300" desc="Back End Software Engineer" />
                </div>
            </div>
        </>
    )
}

export default AboutUs;

function PersonalTable( {img, name, gradient, desc } ) {
    return (
        <div className="flex items-center justify-center sm:justify-start">
            <img className="w-32"src={img} alt={img} />
            <div>
                <p className={`text-2xl font-bold ${gradient} inline-block text-transparent bg-clip-text`}>{name}</p>
                <p className="text-gray-500 dark:text-gray-200">{desc}</p>
            </div>
            
        </div>
    );
}