import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {API} from "../../API";

export const usersAPI = createApi({
    reducerPath: 'usersAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.jwt
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        },
    }),
    endpoints: (builder) => ({
        getUserDetails: builder.query({
            query: () => ({
                url: '/users/me' +
                    '?fields[0]=username' +
                    '&fields[1]=email' +
                    '&fields[2]=studentStatus' +
                    '&populate[interests][fields][0]=programFieldName' +
                    '&populate[university][fields][0]=universityName' +
                    '&populate[avatar][fields][0]=formats' +
                    '&populate[avatar][fields][1]=url' +
                    '&populate[comments][fields][0]=id' +
                    '&populate[reviews][fields][0]=' +
                    '&populate[blogs][fields][0]=' +
                    '&populate[qnas][fields][0]=',
                method: 'GET',
            }),
        }),
        updateUserProfile: builder.mutation({
            query: (updatedUser) => ({
                url: `/users/${updatedUser.id}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedUser),
            }),
        }),
    }),
})

export const {
    useGetUserDetailsQuery,
    useUpdateUserProfileMutation
} = usersAPI
