import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {API} from "../../API";

export const addCommentAPI = createApi({
    reducerPath: 'addCommentAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.jwt
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        },
    }),
    endpoints: (builder) => ({
        addComment: builder.mutation({
            query: (postData) => ({
                url: `/comments`,
                method: "POST",
                body: { data: postData.newPostData },
            }),
        }),
    }),
})

export const { useAddCommentMutation } = addCommentAPI
