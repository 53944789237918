// Images
import CrossBtnLight from '../images/icons/CrossLight.png';
import SCLogoWhite from '../images/logos/SCLogoWhiteBG.png';

import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'

import {userLogin} from "../app/features/authentication/AuthenticationInteractions";
import {clearError} from "../app/features/authentication/AuthenticationReducer";
import {toggleUIState} from "../app/features/ui/UIReducer";

function Login({loginMessage}) {

    useEffect(() => {
        // Disable background scrolling
        document.body.style.overflow = 'hidden';
        
        // Clean up function to re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const {isLoading, error, user} = useSelector((state) => state.auth)
    const {showLogin, showLoginPost} = useSelector((state) => state.ui)

    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        const password = e.target.elements.password.value;

        dispatch(userLogin({email, password}))
    };

    useEffect(() => {
        if (user) {
            if (showLogin) {
                dispatch(toggleUIState({key: 'showLogin'}));
                dispatch(toggleUIState({key: 'showProfile'}));
            }

            if (showLoginPost) {
                dispatch(toggleUIState({key: 'showLoginPost'}));
                dispatch(toggleUIState({key: 'showCreatePost'}));
            }
        }
    }, [user, dispatch, showLogin, showLoginPost]);

    useEffect(() => {
        return () => {
            dispatch(clearError());
        };
    }, [dispatch]);

    return (
        <div className="fixed top-0 left-0 sm:m-0 w-screen h-[100dvh] flex justify-center items-center z-10 bg-black bg-opacity-50 overflow-y-auto">

            <div className="popUpStyling overflow-y-scroll">
                <div className="flex justify-end items-center relative w-full h-[8vh] mt-12 sm:mt-0">
                    <img src={CrossBtnLight}
                         className="h-[50px] ml-3 bg-white dark:bg-gray-600 rounded-md shadow-md sm:hover:shadow-xl transition duration-300 cursor-pointer"
                         alt="Cancel button"
                         onClick={() => {
                             dispatch(toggleUIState({key: 'showLogin', value: false}))
                             dispatch(toggleUIState({ key: 'showLoginPost', value: false }))
                         }}/>
                </div>

                <img src={SCLogoWhite} className="h-1/6" alt="Student's Choice Logo"/>

                <h1 className="text-center mb-0 text-2xl font-bold text-sc-red dark:text-gray-300">{loginMessage}</h1>

                <form className="w-4/5 m-auto flex-col items-center" onSubmit={handleSubmit}>
                    <h2 className="inputTitleText">Email</h2>
                    <input className="editInputStyling" name="email" type="email" placeholder="Enter your email"
                           required/>

                    <h2 className="inputTitleText">Password</h2>
                    <input className="editInputStyling" name="password" type="password"
                           placeholder="Enter your password" required/>
                    <br/>

                    <button type="submit" className="popUpButtonStyling2 bg-rose-900" disabled={isLoading}>
                        {isLoading ? "Logging in..." : "Log In"}
                    </button>
                    <button className="popUpButtonStyling" onClick={() => {
                        dispatch(toggleUIState({key: 'showSignUp'}));
                        dispatch(toggleUIState({key: 'showLogin'}));
                    }}>
                        New? Sign Up
                    </button>
                    {error && <h4 className="error">Incorrect email or password, please try again!</h4>}
                </form>

                <br/>

            </div>
        </div>
    );
}

export default Login;