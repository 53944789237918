// InteractionArea.js

import React, { useEffect } from 'react';
import { BASE_URL } from "../../API.js";

// Components
import ReviewCard from './ReviewCard.js';
import DropDownButton from '../Elements/DropDownButton.js';

// Import Tailwind
import '../../input.css';
import {setInteractionName, setSelectedButton, toggleUIState} from "../../app/features/ui/UIReducer.js";
import { useDispatch, useSelector } from "react-redux";

// Named import for fetching all icons
import { useGetAllIconsQuery } from '../../app/service/iconsAPI.js';
import {useGetBlogDataQuery, useGetQnADataQuery, useGetReviewDataQuery} from "../../app/service/any-pagesAPI.js";

function InteractionArea(args) {

    // Icon ID numbers
    const iconIds = {
        reviewSelected: 14,
        reviewDarkMode: 9,
        reviewLightMode: 13,
        commentSelected: 17,
        commentDarkMode: 18,
        commentLightMode: 19,
        FAQSelected: 7,
        FAQDarkMode: 20,
        FAQLightMode: 21,
        plusRed: 22,
        plusWhite: 23
    };

    // For fetching all icons
    const { data: iconsData, isError: isIconsError, isLoading: isIconsLoading } = useGetAllIconsQuery();

    // Helper function to get icon URL by ID
    const getIconURL = (id) => {
        if (!iconsData || !iconsData.data || isIconsLoading || isIconsError) return '';
        // Search through the iconsData array to find the first icon object where the id property of the icon matches the id value provided (If not found icon = undefined)
        const icon = iconsData.data.find(icon => icon.id === id);
        return icon ? `${BASE_URL}${icon.attributes.image.data[0].attributes.formats.thumbnail.url}` : '';
    };

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { selectedButton } = useSelector((state) => state.ui);

    // use this to get values from Strapi
    const interactionName = args.interactionName
    dispatch(setInteractionName(interactionName));

    const { data: reviewsData, isLoading: reviewLoading, refetch: reviewRefetch } = useGetReviewDataQuery(interactionName);
    const { data: blogsData, isLoading: blogsLoading, refetch: blogsRefetch} = useGetBlogDataQuery(interactionName);
    const { data: qnasData, isLoading: qnasLoading, refetch: qnasRefetch } = useGetQnADataQuery(interactionName);

    useEffect(() => {
        reviewRefetch();
        blogsRefetch();
        qnasRefetch();

    }, [reviewRefetch, blogsRefetch, qnasRefetch]);


    const handleButtonClick = (id) => {
        dispatch(setSelectedButton(id));
    };

    // Reviews section (put strapi data here for reviews using data collected by interactionName)
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        return `${formattedDay}/${formattedMonth}/${year}`;
    };

    // check if we have any reviews for this area
    const reviewContent = reviewLoading ? (
        <p className="text-center text-2xl">Loading data...</p>
    ) : reviewsData.data.attributes.reviews.data.length > 0 ? (
        <div className="flex flex-col justify-evenly items-center">
            {/* for each review, create a ReviewCard (add limits at a later stage) */}
            {[...reviewsData.data.attributes.reviews.data ]
                .sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt))
                .map((review, index) => (
                    <ReviewCard
                        key={index}
                        id={review.id}
                        name={review.attributes.users_permissions_user.data.attributes.username}
                        avatar={review.attributes.users_permissions_user.data.attributes.avatar.data ? review.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail : null}
                        like={review.attributes.reviewLikes}
                        desc={review.attributes.reviewText}
                        posttime={formatDate(review.attributes.createdAt)}
                        rating={review.attributes.reviewRating}
                        replies={review.attributes.comments}
                    />
                ))}
        </div>
    ) : (
        <div>
            <p className="text-gray-500 text-center text-2xl">There are no reviews to show here. <br></br> Be the first to post!</p>
        </div>
    );

    const blogContent = blogsLoading ? (
        <p className="text-center text-2xl">Loading data...</p>
    ) : blogsData.data.attributes.blogs.data.length > 0 ? (
        <div className="flex flex-col justify-evenly items-center">
            {[...blogsData.data.attributes.blogs.data]
                .sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt))
                .map((blog, index) => (
                    <ReviewCard
                        key={index}
                        id={blog.id}
                        name={blog.attributes.users_permissions_user.data.attributes.username}
                        avatar={blog.attributes.users_permissions_user.data.attributes.avatar.data ? blog.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail : null}
                        like={blog.attributes.blogLikes}
                        desc={blog.attributes.blogText}
                        posttime={formatDate(blog.attributes.createdAt)}
                        replies={blog.attributes.comments} // Passing comments to the ReviewCard
                    />
                ))}
        </div>
    ) : (
        <div>
            <p className="text-gray-500 text-center text-2xl">There are no blogs to show here. <br></br> Be the first to post!</p>
        </div>
    );

    const qnaContent = qnasLoading ? (
        <p className="text-center text-2xl">Loading data...</p>
    ) : qnasData.data.attributes.qnas.data.length > 0 ? (
        <div className="flex flex-col justify-evenly items-center">
            {[...qnasData.data.attributes.qnas.data]
                .sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt))
                .map((review, index) => (
                    <ReviewCard
                        key={index}
                        id={review.id}
                        name={review.attributes.users_permissions_user.data.attributes.username}
                        avatar={review.attributes.users_permissions_user.data.attributes.avatar.data ? review.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail : null}
                        like={review.attributes.qnaLikes}
                        desc={review.attributes.qnaText}
                        posttime={formatDate(review.attributes.createdAt)}
                        replies={review.attributes.comments}
                    />
                ))}
        </div>
    ) : (
        <div>
            <p className="text-gray-500 text-center text-2xl">There are no questions to show here. <br></br> Be the first to ask a question!</p>
        </div>
    );


    return (
        <div className="mt-[3%] min-h-[300px] h-auto w-11/12 mx-auto rounded-md sm:p-4">
            <div className="mt-[20px] sm:hidden text-center">
                <DropDownButton onSelection={handleButtonClick} />
            </div>

            {/* Buttons to swap content */}
            <div className="hidden sm:flex justify-around w-full">

                {!isIconsLoading &&
                    <ActionButton
                        id="review"
                        image={getIconURL(iconIds.reviewLightMode)}
                        darkImage={getIconURL(iconIds.reviewDarkMode)}
                        selectedImage={getIconURL(iconIds.reviewSelected)}
                        text="Reviews"
                        isSelected={selectedButton === 'review'}
                        onClick={() => handleButtonClick('review')}
                    />
                }

                {!isIconsLoading &&
                    <ActionButton
                        id="blog"
                        image={getIconURL(iconIds.commentLightMode)}
                        darkImage={getIconURL(iconIds.commentDarkMode)}
                        selectedImage={getIconURL(iconIds.commentSelected)}
                        text="Blog"
                        isSelected={selectedButton === 'blog'}
                        onClick={() => handleButtonClick('blog')}
                    />
                }

                {!isIconsLoading &&
                    <ActionButton
                        id="qna"
                        image={getIconURL(iconIds.FAQLightMode)}
                        darkImage={getIconURL(iconIds.FAQDarkMode)}
                        selectedImage={getIconURL(iconIds.FAQSelected)}
                        text="Ask Questions"
                        isSelected={selectedButton === 'qna'}
                        onClick={() => handleButtonClick('qna')}
                    />
                }
            </div>

            {/* red line */}
            <div className="h-[2px] bg-sc-red w-11/12 mx-auto mt-[7%] sm:w-[95%] sm:mt-[2%]" />

            {/* Add post button */}
            <div className="group sm:hover:bg-sc-red bg-white dark:bg-gray-700 flex justify-between items-center w-full max-h-[30px] ml-auto mr-auto 
                sm:justify-evenly rounded-md sm:w-1/5 sm:max-h-[50px] mt-2 sm:float-right 
                sm:mr-[4%] shadow-md p-6 sm:p-2 sm:hover:shadow-xl transition duration-300 cursor-pointer"
                onClick={() => {
                    user ? dispatch(toggleUIState({ key: 'showCreatePost' })) : dispatch(toggleUIState({ key: 'showLoginPost' }))
                }}>
                <p id="postButton" className="group-hover:text-gray-200 text-rose-900 dark:text-gray-200 font-bold">
                    Add Post
                </p>

                {!isIconsLoading &&
                    <img src={getIconURL(iconIds.plusRed)} className="mr-2 w-[8%] sm:w-[10%] self-center h-full group-hover:hidden dark:hidden transition duration-300" alt="Plus Icon" />
                }

                {!isIconsLoading &&
                    <img src={getIconURL(iconIds.plusWhite)} className="mr-2 w-[8%] sm:w-[10%] self-center h-full hidden group-hover:block dark:block transition duration-300" alt="Plus Icon" />
                }
            </div>

            <div id="showDiv" className="mt-[2%] sm:mt-[8%]">
                {selectedButton === 'review' && reviewContent}
                {selectedButton === 'blog' && blogContent}
                {selectedButton === 'qna' && qnaContent}
            </div>
        </div>
    );
}

function ActionButton({ id, image, darkImage, selectedImage, text, isSelected, onClick }) {

    const textCol = isSelected ? 'text-white' : 'text-rose-900 dark:text-white';
    // Set the background color based on isSelected
    const bgColor = isSelected ? 'bg-sc-red' : 'bg-white dark:bg-gray-700';

    return (
        <div
            className={`max-h-16 w-[30%] rounded-lg flex items-center ${bgColor} drop-shadow-md justify-center ${textCol} transition duration-300 sm:hover:drop-shadow-xl cursor-pointer`}
            onClick={onClick}
        >
            <h3 className="font-bold pr-10 text-xl">{text}</h3>
            <img src={isSelected ? selectedImage : image} className="ml-[2%] max-h-[50%] self-center block dark:hidden" alt={`${image} Icon`} />
            <img src={isSelected ? selectedImage : darkImage} className="ml-[2%] max-h-[50%] self-center hidden dark:block" alt={`${darkImage} Icon`} />
        </div>
    );
}

export default InteractionArea;

