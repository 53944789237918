import { useState } from "react";
import PageHeading from "../../components/ProgramsAndSubjectsLoad/PageHeading";
import SelectButtonGroup from "../../components/Elements/SelectButtonGroup";
// import { useGetAllFieldsQuery } from "../../app/service/field-componentsAPI";
import { useGetAllUniversityNamesQuery } from "../../app/service/university-pagesAPI";
import PreviewUniReview from "../PreviewPages/PreviewUniReview";
import PreviewDetailView from "../PreviewPages/PreviewDetailView";

import Add from "../../images/icons/add.png";

function AddContent() {
    const postTypeOptions = [
        { text: "University", type: "university" },
        { text: "Subject", type: "subject" },
        { text: "Course", type: "course" },
    ];

    const [postTypeSelect, setPostTypeSelect] = useState("university");
    const [subjectStatus, setSubjectStatus] = useState('Undegraduate');
    const [institution, setInstitution] = useState();
    const [filteredUniversities, setFilteredUniversities] = useState([]);

    const [showPreview, setShowPreview] = useState(false);

    // State for university details
    const [uniName, setUniName] = useState("");
    const [uniLocation, setUniLocation] = useState("");
    const [uniLogo, setUniLogo] = useState(null);  // This will hold the uploaded logo file
    const [uniBanner, setUniBanner] = useState(null);  // This will hold the uploaded banner file

    // State for detailView
    const [itemName, setItemName] = useState("");
    const [setItemCode] = useState("");
    const [itemDesc, setItemDesc] = useState("");
    const [setItemWebsite] = useState("");

    const handleLogoUpload = (event) => {
        setUniLogo(URL.createObjectURL(event.target.files[0]));  // Convert file to preview URL
    };

    const handleBannerUpload = (event) => {
        setUniBanner(URL.createObjectURL(event.target.files[0]));  // Convert file to preview URL
    };

    // const { data: fields, isLoading: loadingFields } = useGetAllFieldsQuery({ pageType: "subject", fieldNameField: "subjectFieldName" });
    const {data: universities} = useGetAllUniversityNamesQuery();

    const handleUniversityInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        const filtered = universities.data.filter(u => u.attributes.universityName.toLowerCase().includes(input));
        setFilteredUniversities(filtered);
        setInstitution(input);
    };

    const handleUniversityItemClick = (universityName) => {
        setInstitution(universityName);
        setFilteredUniversities([]);
    };

    return (
        <div className="w-screen bg-white dark:bg-gray-900">
            <div className="h-full w-full">
                <PageHeading pageName="Add Content" icon={Add}/>
                <div className="flex justify-center">
                    <SelectButtonGroup
                        options={postTypeOptions}
                        selectedOption={postTypeSelect}
                        onOptionChange={setPostTypeSelect}
                    />
                </div>

                {/* Conditional rendering using if statements */}
                <div className="mt-4">
                    {postTypeSelect === "university" && (
                        <div className="grid grid-cols-2 gap-4 mx-52">
                            <div>
                                <div className="editTextStyling">University Name</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Enter university name"
                                    onChange={(e) => setUniName(e.target.value)}
                                />
                            </div>
                            <div>
                                <div className="editTextStyling">University Location</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Enter university location"
                                    onChange={(e) => setUniLocation(e.target.value)}
                                />
                            </div>
                            <div>
                                <div className="editTextStyling">University Logo</div>
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleLogoUpload}
                                    accept="image/*"
                                />
                                <label
                                    htmlFor="fileInput"
                                    className="block w-full ml-2 appearance-none rounded-md cursor-pointer bg-gray-100 dark:bg-gray-600 p-3 text-gray-600 dark:text-gray-200 border-2 border-dashed border-gray-500"
                                >
                                    Upload Logo
                                </label>
                            </div>
                            <div className="w-full">
                                <div className="editTextStyling">University Backdrop</div>
                                <input
                                    type="file"
                                    id="fileInput2"
                                    className="hidden"
                                    onChange={handleBannerUpload}
                                    accept="image/*"
                                />
                                <label
                                    htmlFor="fileInput2"
                                    className="block w-full ml-2 appearance-none rounded-md cursor-pointer bg-gray-100 dark:bg-gray-600 p-3 text-gray-600 dark:text-gray-200 border-2 border-dashed border-gray-500"
                                >
                                    Upload Backdrop
                                </label>
                            </div>
                            <div className="col-span-2">
                                <div className="editTextStyling">University Website</div>
                                <input class="editInputStyling" placeholder="Paste university website URL"/>
                            </div>
                            <button
                                className="ml-2 shadow-md rounded-md flex justify-evenly items-center text-black bg-white dark:text-gray-800 font-bold cursor-pointer w-full py-2 px-4 mx-auto mt-5 mb-2 sm:hover:shadow-xl transition duration-300"
                                onClick={() => setShowPreview(true)}
                            >
                                Preview
                            </button>
                            <button
                                className="ml-2 shadow-md rounded-md flex justify-evenly items-center text-white bg-sc-red dark:text-gray-200 font-bold cursor-pointer w-full py-2 px-4 mx-auto mt-5 mb-2 sm:hover:shadow-xl transition duration-300"
                                onClick={() => setShowPreview(true)}
                            >
                                Submit
                            </button>
                        </div>
                    )}
                    {postTypeSelect === "subject" && (
                        <div className="grid grid-cols-2 gap-4 mx-52">
                            <div>
                                <div className="editTextStyling">Subject Name</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Enter subject name"
                                    onChange={(e) => setItemName(e.target.value)}/>
                            </div>
                            <div>
                                <div className="editTextStyling">Subject Code</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Enter subject code"
                                    onChange={(e) => setItemCode(e.target.value)}/>
                            </div>
                            <div className="relative z-10">
                                <div className="editTextStyling">University Name</div>
                                <input className="editInputStyling" value={institution}
                                        placeholder="Enter university name"
                                        onChange={handleUniversityInputChange} type='text'/>
                                    {filteredUniversities.length > 0 && (
                                        <ul className="universityList">
                                            {filteredUniversities.map((university, index) => (
                                                <h2 key={index}
                                                    onClick={() => handleUniversityItemClick(university.attributes.universityName)}>
                                                    {university.attributes.universityName}
                                                </h2>
                                            ))}
                                        </ul>
                                    )}
                            </div>
                            <div>
                                <div className="editTextStyling">Graduate Level</div>
                                <select className="editInputStyling" defaultValue={'Undergraduate'}
                                        onChange={(e) => setSubjectStatus(e.target.value)}>
                                    <option value='Undergraduate'>Undergraduate</option>
                                    <option value='Postgraduate'>Postgraduate</option>
                                    <option value='Looking for University'>Research</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <div className="editTextStyling">Subject Website</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Paste subject website URL"
                                    onChange={(e) => setItemWebsite(e.target.value)}
                                />
                            </div>
                            <div className="col-span-2 row-span-2">
                                <div className="editTextStyling">Subject Description</div>
                                <textarea
                                    className="shadow-inner overflow-y-auto w-full h-full sm:h-52 resize-none p-2 rounded-md align-top text-left bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-200 ml-2"
                                    name="postTextInput"
                                    type="postTextInput"
                                    placeholder="Enter the subject description"
                                    required
                                    onChange={(e) => setItemDesc(e.target.value)}
                                />
                            </div>
                            <button
                                className="ml-2 shadow-md rounded-md flex justify-evenly items-center text-black bg-white dark:text-gray-800 font-bold cursor-pointer w-full py-2 px-4 mx-auto mt-5 mb-2 sm:hover:shadow-xl transition duration-300"
                                onClick={() => setShowPreview(true)}
                            >
                                Preview
                            </button>
                            <button
                                className="ml-2 shadow-md rounded-md flex justify-evenly items-center text-white bg-sc-red dark:text-gray-200 font-bold cursor-pointer w-full py-2 px-4 mx-auto mt-5 mb-2 sm:hover:shadow-xl transition duration-300"
                                onClick={() => setShowPreview(true)}
                            >
                                Submit
                            </button>
                        </div>
                        
                    )}
                    {postTypeSelect === "course" && (
                        <div className="grid grid-cols-2 gap-4 mx-52">
                            <div>
                                <div className="editTextStyling">Course Name</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Enter course name"
                                    onChange={(e) => setItemName(e.target.value)}/>
                            </div>
                            <div>
                                <div className="editTextStyling">Course Code</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Enter course code"
                                    onChange={(e) => setItemCode(e.target.value)}/>
                            </div>
                            <div className="relative z-10">
                                <div className="editTextStyling">University Name</div>
                                <input className="editInputStyling" value={institution}
                                        placeholder="Enter university name"
                                        onChange={handleUniversityInputChange} type='text'/>
                                    {filteredUniversities.length > 0 && (
                                        <ul className="universityList">
                                            {filteredUniversities.map((university, index) => (
                                                <h2 key={index}
                                                    onClick={() => handleUniversityItemClick(university.attributes.universityName)}>
                                                    {university.attributes.universityName}
                                                </h2>
                                            ))}
                                        </ul>
                                    )}
                            </div>
                            <div>
                                <div className="editTextStyling">Graduate Level</div>
                                <select className="editInputStyling" defaultValue={'Undergraduate'}
                                        onChange={(e) => setSubjectStatus(e.target.value)}>
                                    <option value='Undergraduate'>Undergraduate</option>
                                    <option value='Postgraduate'>Postgraduate</option>
                                    <option value='Looking for University'>Research</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <div className="editTextStyling">Course Website</div>
                                <input 
                                    class="editInputStyling" 
                                    placeholder="Paste course website URL"
                                    onChange={(e) => setItemWebsite(e.target.value)}
                                />
                            </div>
                            <div className="col-span-2 row-span-2">
                                <div className="editTextStyling">Course Description</div>
                                <textarea
                                    className="shadow-inner overflow-y-auto w-full h-full sm:h-52 resize-none p-2 rounded-md align-top text-left bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-200 ml-2"
                                    name="postTextInput"
                                    type="postTextInput"
                                    placeholder="Enter the course description"
                                    required
                                    onChange={(e) => setItemDesc(e.target.value)}
                                />
                            </div>
                            <button
                                className="ml-2 shadow-md rounded-md flex justify-evenly items-center text-black bg-white dark:text-gray-800 font-bold cursor-pointer w-full py-2 px-4 mx-auto mt-5 mb-2 sm:hover:shadow-xl transition duration-300"
                                onClick={() => setShowPreview(true)}
                            >
                                Preview
                            </button>
                            <button
                                className="ml-2 shadow-md rounded-md flex justify-evenly items-center text-white bg-sc-red dark:text-gray-200 font-bold cursor-pointer w-full py-2 px-4 mx-auto mt-5 mb-2 sm:hover:shadow-xl transition duration-300"
                                onClick={() => setShowPreview(true)}
                            >
                                Submit
                            </button>
                        </div>
                    )}

                    {/* Preview Component */}
                    {showPreview && postTypeSelect === "university" && (
                        <PreviewUniReview
                            uniBanner={uniBanner}
                            uniName={uniName}
                            uniLocation={uniLocation}
                            uniLogo={uniLogo}
                            onClose={() => setShowPreview(false)}
                        />
                    )}

                    {showPreview && postTypeSelect !== "university" && (
                        <PreviewDetailView
                            uniName={institution}
                            itemName={itemName}
                            itemGrad={subjectStatus}
                            itemDesc={itemDesc}
                            onClose={() => setShowPreview(false)}
                        />
                    )}
                    
                </div>
            </div>
        </div>
    );
}

export default AddContent;