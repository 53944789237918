import { createSlice } from '@reduxjs/toolkit'
import { registerUser, userLogin } from './AuthenticationInteractions'


const jwt = localStorage.getItem('jwt')
    ? localStorage.getItem('jwt')
    : null


const initialState = {
    isLoading: false,
    user: null,
    jwt,
    error: null,
    success: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('jwt')
            state.isLoading = false
            state.user = null
            state.jwt = null
            state.error = null
        },
        setCredentials: (state, { payload }) => {
            state.user = payload
        },
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // login user
        builder
            .addCase(userLogin.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.user = payload.user
                state.jwt = payload.jwt
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.isLoading = false
                state.error = payload
            })
            // register user
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(registerUser.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true // registration successful
            })
            .addCase(registerUser.rejected, (state, { payload }) => {
                state.isLoading = false
                state.error = payload
            })
    },
})

export const { logout, setCredentials, clearError } = authSlice.actions

export default authSlice.reducer
