import React, { useState, useCallback } from 'react';
import { BASE_URL } from "../../API.js";
import '../../input.css';

// Logos
import FaceBook from '../../images/logos/FacebookIcon.png';
import X from '../../images/logos/XIcon.png';

import CommentPopup from './CommentsPopup.js';

// Named import for fetching all icons
import { useGetAllIconsQuery } from '../../app/service/iconsAPI.js';

function ReviewCard(args) {
    // Icon ID numbers
    const iconIds = {
        starYellow: 1,
        heartGray: 13,
        heartPink: 14,
        disloveGray: 15,
        disloveBlue: 16,
        boldCommentGray: 17,
        paperPlaneGray: 18,
        defaultProfilePhoto: 19,
        heartWhite: 21,
        disloveWhite: 22,
        boldCommentWhite: 23,
        paperPlaneWhite: 24,
        dots: 30
    };

    // For fetching all icons
    const { data: iconsData, isError: isIconsError, isLoading: isIconsLoading } = useGetAllIconsQuery();

    // Helper function to get icon URL by ID
    const getIconURL = useCallback((id) => {
        if (!iconsData || !iconsData.data || isIconsLoading || isIconsError) return '';
        const icon = iconsData.data.find(icon => icon.id === id);
        return icon ? `${BASE_URL}${icon.attributes.image.data[0].attributes.formats.thumbnail.url}` : '';
    }, [iconsData, isIconsLoading, isIconsError]);

    // Check whether the user’s system is set to a dark color scheme
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const isDarkMode = darkModeQuery.matches;

    const unclickedLikeColor = isDarkMode ? 'text-white' : 'text-gray-500';
    const unclickedDislovecolor = isDarkMode ? 'text-white' : 'text-gray-500';
    const boldCommentColor = isDarkMode ? ' text-gray-100' : ' text-gray-500';
    const paperPlaneColor = isDarkMode ? ' text-gray-100' : ' text-gray-500';

    const [likeColor, setLikeColor] = useState(unclickedLikeColor);
    const [likeCount, setLikeCount] = useState(args.like ? +args.like : 0);

    const [disloveColor, setDisloveColor] = useState(unclickedDislovecolor);
    const [disloveCount, setDisloveCount] = useState(args.dislove ? +args.dislove : 0);

    const [showSharePopup, setShowSharePopup] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [showDotsPopup, setShowDotsPopup] = useState(false);

    const handleLikeClick = () => {
        const isRed = likeColor === 'text-red-500';
        const wasDisliked = disloveColor === 'text-blue-500';

        setLikeColor(isRed ? unclickedLikeColor : 'text-red-500');
        setLikeCount(prevCount => isRed ? prevCount - 1 : prevCount + 1);

        if (!isRed && wasDisliked) {
            setDisloveColor(unclickedDislovecolor);
            setDisloveCount(prevCount => prevCount - 1);
        }
    };

    const handleDisloveClick = () => {
        const isBlue = disloveColor === 'text-blue-500';
        const wasLiked = likeColor === 'text-red-500';

        setDisloveColor(isBlue ? unclickedDislovecolor : 'text-blue-500');
        setDisloveCount(prevCount => isBlue ? prevCount - 1 : prevCount + 1);

        if (!isBlue && wasLiked) {
            setLikeColor(unclickedLikeColor);
            setLikeCount(prevCount => prevCount - 1);
        }
    };

    const toggleSharePopup = () => {
        if (showSharePopup) {
            setIsClosing(true);
            setTimeout(() => {
                setShowSharePopup(false);
                setIsClosing(false);
            }, 150);
        } else {
            setShowSharePopup(true);
        }
    };

    const toggleCommentPopup = () => {
        setShowCommentPopup(prevState => !prevState);
        console.log('Replies:', args.replies);
    };

    const toggleDotsPopup = () => {
        setShowDotsPopup(prevState => !prevState);
    };

    return (
        <div className="w-full sm:w-11/12 h-auto bg-white dark:bg-gray-700 shadow-md mb-4 rounded-lg pl-2 sm:hover:shadow-xl transition duration-300 m-auto relative">
            <div className="flex items-center pt-2">
                <div className="w-14 h-12 sm:w-16 sm:h-16 overflow-hidden rounded-full">
                    {!isIconsLoading &&
                        <img className="w-full h-full object-cover"
                            src={args.avatar == null ? getIconURL(iconIds.defaultProfilePhoto) : `${BASE_URL}${args.avatar.url}`} alt="Profile avatar" />
                    }
                </div>
                <div className="flex justify-between w-full px-6 items-center ml-auto">
                    <h1 className="ml-[-6%] sm:ml-0 text-2xl sm:text-3xl font-bold text-gray-400">{args.name ? args.name : "Anonymous"}</h1>
                    <div className="flex h-1/2 items-center justify-end space-x-1">
                        <p className="text-yellow-500 font-bold">{args.rating ? args.rating : ""}</p>
                        {!isIconsLoading && args.rating ? <img src={getIconURL(iconIds.starYellow)} className="w-4 h-4" alt="Star icon" /> : ""}
                        <h2 className="text-base sm:text-xl font-semibold text-gray-400 ml-2">{args.posttime ? args.posttime : "Just now"}</h2>
                        <button onClick={toggleDotsPopup}>
                            <img src={getIconURL(iconIds.dots)} className="w-6" alt="Options"/>
                        </button>
                        {showDotsPopup && (
                            <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-20 p-2 w-[100px]">
                                <ul className="space-y-2">
                                    <li className="cursor-pointer hover:bg-gray-100 p-2 rounded text-red-600">Report</li>
                                    <li className="cursor-pointer hover:bg-gray-100 p-2 rounded text-red-600">Block</li>
                                    <li className="cursor-pointer hover:bg-gray-100 p-2 rounded">Hide</li>
                                    <li onClick={toggleDotsPopup} className="cursor-pointer hover:bg-gray-100 p-2 rounded text-blue-500">Cancel</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="border-t border-gray-300 mr-5 mt-1" />

            <div>
                <p className="text-gray-600 dark:text-gray-200 font-normal text-sm sm:text-base pr-4">{args.desc}</p>
            </div>

            <div className="flex relative">
                <button onClick={handleLikeClick}>
                <div className="flex pr-5 items-center py-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={`${likeColor} w-7`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                        />
                    </svg>

                    <p className={`${likeColor} text-base`}>
                        {likeCount}
                    </p>
                </div>
                </button>
                <button onClick={handleDisloveClick}>
                    <div className="flex pr-5 items-center py-3">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth="1.5" 
                            stroke="currentColor" 
                            className={`${disloveColor} w-7`}
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54" 
                            />
                        </svg>

                        <p className={`${disloveColor} text-base`}>{disloveCount}</p>
                    </div>
                </button>
                <button onClick={toggleCommentPopup}>
                    <div className="flex pr-5 items-center py-3">
                        {!isIconsLoading &&
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                strokeWidth="1.5" 
                                stroke="currentColor" 
                                className={`${boldCommentColor} w-7`}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                            </svg>
                          
                        }
                        <p className={`text-base ${boldCommentColor}`}>{args.replies.data.length ? args.replies.data.length : 0}</p>
                    </div>
                </button>
                {showCommentPopup && !isIconsLoading && (
                    <CommentPopup
                        initialID={args.id}
                        initialName={args.name}
                        initialComment={args.comment}
                        initialCommentCount={args.replies.data.length}
                        initialLikeCount={likeCount}
                        initialDisloveCount={disloveCount}
                        initialDesc={args.desc}
                        initialPosttime={args.posttime}
                        initialAvatar={args.avatar == null ? getIconURL(iconIds.defaultProfilePhoto) : `${BASE_URL}${args.avatar.url}`}
                        followingComments={args.replies}
                        onClose={toggleCommentPopup}
                    />
                )}
                <button onClick={toggleSharePopup} className="relative">
                    <div className="flex items-center py-3">
                        {!isIconsLoading &&
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                strokeWidth="1.5" 
                                stroke="currentColor" 
                                className={`${paperPlaneColor} w-7`}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                            </svg>
                          
                        }
                        <p className={`text-base ${paperPlaneColor}`}>{args.share ? args.share : 0}</p>
                    </div>

                    {(showSharePopup || isClosing) && (
                        <div className={`absolute bottom-full left-0 mt-2 shadow-lg z-10 p-4 bg-white border border-gray-300 rounded-lg w-[115px] ${isClosing ? 'animate-fadeOutDown' : 'animate-fadeInUp'}`}>
                            <div className="flex items-center space-x-4">
                                <img
                                    src={FaceBook}
                                    alt="Facebook"
                                    onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstudentschoice.blog%2F&amp;src=sdkpreparse', '_blank')}
                                    className="w-8 h-8 cursor-pointer rounded-md transition-transform transform hover:scale-110 hover:shadow-md"
                                />
                                <img
                                    src={X}
                                    alt="X"
                                    onClick={() => {
                                        const tweetText = `${args.desc} %0a%0aCheck out Students Choice! A university review site from students, for students. Discover universities, share experiences, make informed decisions. studentschoice.blog`;
                                        const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
                                        window.open(twitterUrl, '_blank');
                                    }}
                                    className="w-8 h-8 cursor-pointer rounded-md transition-transform transform hover:scale-110 hover:shadow-md"
                                />
                            </div>
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
}

export default ReviewCard;