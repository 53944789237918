import {useDispatch, useSelector} from "react-redux";
import {setFieldSelected} from "../../app/features/filteringSubjectsAndPrograms/filteringSubjectsAndProgramsReducer";
import {useGetAllFieldsQuery} from "../../app/service/field-componentsAPI";

function FieldsSelection({pageType}) {
    const headingTitle = "Fields";
    const fieldNameField = `${pageType}FieldName`;

    const dispatch = useDispatch();
    const selectedFieldId = useSelector(state => state.filteringSubjectsAndPrograms.fieldSelected);

    const handleFieldClick = (id) => {
        if (id === selectedFieldId) {
            dispatch(setFieldSelected(""));
        } else {
            dispatch(setFieldSelected(id));
        }
    };

    const {data: fields, isLoading: loadingFields} = useGetAllFieldsQuery({pageType, fieldNameField});

    return (
        <div className="primaryPageSizing">
            <h2 className="titleTextSecondary">{headingTitle}</h2>
            <div className="flex flex-col sm:grid sm:grid-cols-4 2xl:grid-cols-5 gap-4">
                {loadingFields ? (
                    // Skeleton loaders
                    [...Array(16)].map((_, index) => (
                        <div
                            key={index}
                            className="animate-pulse h-12 w-full bg-gray-300 rounded-md dark:bg-gray-700"
                        />
                    ))
                ) : (
                    fields.data.map((field) => (
                        <div
                            key={field.id}
                            id={field.id}
                            className={field.id === selectedFieldId ? "fieldSelectedStyling" : "fieldStyling"}
                            onClick={() => handleFieldClick(field.id)}
                        >
                            {field.attributes[fieldNameField]}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default FieldsSelection;