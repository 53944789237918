import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "../../API";

export const fuzzySearchAPI = createApi({
    reducerPath: 'fuzzySearchAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
    }),
    endpoints: (builder) => ({
        fuzzySearch: builder.query({
            query: (searchQuery) => `/fuzzy-search/search?query=${searchQuery}`,
        }),
    }),
});

export const {
    useFuzzySearchQuery
} = fuzzySearchAPI;

