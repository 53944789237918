import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "../../API";

export const fieldComponentsAPI = createApi({
    reducerPath: 'fieldComponentsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
    }),
    endpoints: (builder) => ({
        getAllFields: builder.query({
            query: (data) => `/${data.pageType}-field-components?fields[0]=${data.fieldNameField}`,
        }),
    }),
});

export const {
    useGetAllFieldsQuery
} = fieldComponentsAPI;

