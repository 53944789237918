import {createAsyncThunk} from '@reduxjs/toolkit'
import {API} from "../../../API";

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({email, password}, {rejectWithValue}) => {

        try {
            const value = {
                identifier: email,
                password: password,
            };

            const response = await fetch(`${API}/auth/local`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            if (!response.ok) {
                const error = await response.json();
                return rejectWithValue(error.error.message);
            }

            const data = await response.json();

            localStorage.setItem('jwt', data.jwt)

            const userDetailsResponse = await fetch(`${API}/users/me?fields[0]=username&fields[1]=email&fields[2]=studentStatus&populate[university][fields][0]=universityName&populate[avatar][fields][0]=formats&populate[avatar][fields][1]=url&populate[comments][fields][0]=id&populate[reviews][fields][0]=&populate[blogs][fields][0]=&populate[qnas][fields][0]=`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${data.jwt}`,
                },
            });

            if (!userDetailsResponse.ok) {
                const error = await userDetailsResponse.json();
                return rejectWithValue(error.message);
            }

            const userDetails = await userDetailsResponse.json();

            return {...data, user: userDetails};
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const registerUser = createAsyncThunk(
    'auth/register',
    async ({ username, email, password }, { rejectWithValue }) => {
        try {
            const usernameRegex = /^[a-zA-Z0-9]+$/;

            if (!username.trim() || !usernameRegex.test(username)) {
                return rejectWithValue("Username must contain only letters and numbers, and cannot be empty.");
            }

            const response = await fetch(`${API}/auth/local/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, email, password }),
            });

            const data = await response.json();
            if (data?.error) {
                throw data?.error;
            }


        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)