import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { universityPagesAPI } from "./service/university-pagesAPI";
import { iconsAPI } from "./service/iconsAPI";
import authReducer from "./features/authentication/AuthenticationReducer";
import uiReducer from "./features/ui/UIReducer";
import { usersAPI } from "./service/usersAPI";
import { addPostAPI } from "./service/addPostAPI";
import { uploadAPI } from "./service/uploadAPI";
import { fuzzySearchAPI } from "./service/fuzzy-search";
import filteringSubjectsAndProgramsReducer from "./features/filteringSubjectsAndPrograms/filteringSubjectsAndProgramsReducer";
import { fieldComponentsAPI } from "./service/field-componentsAPI";
import { anyPagesAPI } from "./service/any-pagesAPI";
import { homepageAPI } from './service/homepageAPI';
import { universitiesPageTextAPI } from './service/universitiesPageTextAPI';
import { addCommentAPI } from "./service/addCommentAPI";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        ui: uiReducer,
        filteringSubjectsAndPrograms: filteringSubjectsAndProgramsReducer,
        [anyPagesAPI.reducerPath]: anyPagesAPI.reducer,
        [addPostAPI.reducerPath]: addPostAPI.reducer,
        [addCommentAPI.reducerPath]: addCommentAPI.reducer,
        [fieldComponentsAPI.reducerPath]: fieldComponentsAPI.reducer,
        [fuzzySearchAPI.reducerPath]: fuzzySearchAPI.reducer,
        [universityPagesAPI.reducerPath]: universityPagesAPI.reducer,
        [iconsAPI.reducerPath]: iconsAPI.reducer,
        [uploadAPI.reducerPath]: uploadAPI.reducer,
        [usersAPI.reducerPath]: usersAPI.reducer,
        [homepageAPI.reducerPath]: homepageAPI.reducer,
        [universitiesPageTextAPI.reducerPath]: universitiesPageTextAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(addPostAPI.middleware)
            .concat(anyPagesAPI.middleware)
            .concat(addCommentAPI.middleware)
            .concat(fieldComponentsAPI.middleware)
            .concat(fuzzySearchAPI.middleware)
            .concat(universityPagesAPI.middleware)
            .concat(iconsAPI.middleware)
            .concat(uploadAPI.middleware)
            .concat(usersAPI.middleware)
            .concat(homepageAPI.middleware)
            .concat(universitiesPageTextAPI.middleware),

})

setupListeners(store.dispatch);
export default store
