import React, { useState, useEffect } from 'react';
import CrossBtnLight from '../../images/icons/CrossLight.png';
import { BASE_URL } from "../../API";
import { useAddCommentMutation } from "../../app/service/addCommentAPI";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../app/features/authentication/AuthenticationReducer";
import { useGetUserDetailsQuery } from "../../app/service/usersAPI";
import { useGetBlogDataQuery, useGetQnADataQuery, useGetReviewDataQuery } from "../../app/service/any-pagesAPI";

function CommentsPopup(args) {

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        return `${formattedDay}/${formattedMonth}/${year}`;
    };

    useEffect(() => {
        // Disable background scrolling
        document.body.style.overflow = 'hidden';
        
        // Clean up function to re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { interactionName } = useSelector((state) => state.ui);
    const { selectedButton } = useSelector((state) => state.ui);

    const { refetch } = useGetUserDetailsQuery();
    const { refetch: reviewRefetch } = useGetReviewDataQuery(interactionName);
    const { refetch: blogsRefetch } = useGetBlogDataQuery(interactionName);
    const { refetch: qnasRefetch } = useGetQnADataQuery(interactionName);
    const [replyText, setReplyText] = useState("");
    const [sendingReply, setSendingReply] = useState(false);
    const [error, setError] = useState("");  // State for error message
    const [showErrorPopup, setShowErrorPopup] = useState(false);  // State to control popup visibility

    const [addComment] = useAddCommentMutation();

    const handleAddComment = async (values) => {
        try {
            const result = await addComment(values).unwrap();
            console.log(result);
        } catch (error) {
            console.error(error);
        }
    };

    const handleReply = async () => {
        setSendingReply(true);
    
        // Trim the reply text to remove leading and trailing whitespace
        const trimmedReplyText = replyText.trim();
    
        // Check if the trimmed reply text is empty
        if (!trimmedReplyText) {
            setError("Comment cannot be empty or just spaces");
            setShowErrorPopup(true);
            setSendingReply(false);  // Reset sending state
            
            // Hide the popup after 3 seconds
            setTimeout(() => {
                setShowErrorPopup(false);
            }, 3000);
            
            return;
        }
    
        const getPostData = () => {
            const baseData = {
                commentText: trimmedReplyText,  // Use trimmed text
                commentLikes: 0,
                users_permissions_user: user.id,
            };
    
            switch (selectedButton) {
                case "review":
                    return {
                        ...baseData,
                        reviews: { id: args.initialID },
                    };
                case "qna":
                    return {
                        ...baseData,
                        qnas: { id: args.initialID },
                    };
                default:
                    return {
                        ...baseData,
                        blogs: { id: args.initialID },
                    };
            }
        };
    
        const newPostData = getPostData();
    
        try {
            await handleAddComment({ newPostData });
    
            const updatedUserProfile = await refetch();
            setReplyText(""); // Clear the reply text after successful submission
    
            dispatch(setCredentials(updatedUserProfile.data));
    
            // Refetch data
            reviewRefetch();
            blogsRefetch();
            qnasRefetch();
        } catch (error) {
            console.error(error);
        } finally {
            setSendingReply(false); // Ensure the sending state is reset
        }
    };

    return (
        <div className="fixed top-0 left-0 m-0 w-screen h-[100dvh] flex justify-center items-center z-10 bg-black bg-opacity-50" style={{paddingBottom: 'env(safe-area-inset-bottom)'}}>
            <div className="popUpStyling relative h-full w-full sm:h-4/5 sm:w-4/5 sm:rounded-md">
                <div className="flex justify-end items-center relative w-full h-[8vh] sm:mt-0">
                    <img src={CrossBtnLight}
                        className="h-[50px] ml-3 bg-white dark:bg-gray-700 rounded-md shadow-md sm:hover:shadow-xl transition duration-300 cursor-pointer"
                        alt="Cancel button"
                        onClick={args.onClose}
                    />
                </div>

                {/* Error Popup */}
                {showErrorPopup && (
                    <div className="absolute top-0 left-1/2 mt-5 text-center transform -translate-x-1/2 bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-300 py-2 px-4 rounded-md shadow-lg">
                        {error}
                    </div>
                )}

                <div className="w-full h-full overflow-y-auto mb-[5%] sm:mb-0">
                    {/* Comment they are replying to */}
                    <InitialComment
                        name={args.initialName}
                        commentCount={args.initialCommentCount}
                        desc={args.initialDesc}
                        like={args.initialLikeCount}
                        dislike={args.initialDisloveCount}
                        posttime={args.initialPosttime}
                        avatar={args.initialAvatar}
                    />

                    {/* Display all additional replies */}
                    <div className="h-full mb-24">
                        {args.followingComments && args.followingComments.data.length > 0 ? (
                            <div>
                                {args.followingComments.data.map((reply, index) => (
                                    <Replies
                                        key={index}
                                        name={reply.attributes.users_permissions_user.data.attributes.username}
                                        desc={reply.attributes.commentText}
                                        posttime={formatDate(reply.attributes.createdAt)}
                                        avatar={
                                            reply.attributes.users_permissions_user.data.attributes.avatar.data ?
                                                `${BASE_URL}${reply.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail.url}`
                                                :
                                                `${BASE_URL}/uploads/Default_Profile_Photo_3220d06254.jpg`
                                        }
                                    />
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-500 text-center">No replies to show.</p>
                        )}
                    </div>
                    

                    {/* Input for replying */}
                    { user ?
                        <div className="flex flex-col sm:flex-row items-center justify-center absolute bottom-0 w-full bg-white dark:bg-gray-800 max-w-full p-1 mx-auto ml-[-8px] sm:ml-[-24px]">
                            <input
                                className="editInputStyling w-full sm:w-full"
                                placeholder="Type your reply"
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                            />
                            {sendingReply ?
                                <button
                                    className="cursor-pointer inline-block bg-rose-900 hover:bg-rose-950 text-white font-bold py-3 px-4 rounded transition duration-300 mt-2 sm:mt-0 sm:ml-2"
                                    disabled={sendingReply}
                                >
                                    Sending Reply...
                                </button>
                                :
                                <button
                                    className="cursor-pointer inline-block bg-rose-900 hover:bg-rose-950 text-white font-bold py-3 px-4 rounded transition duration-300 mt-2 w-full sm:w-[100px] sm:mt-0 sm:ml-2"
                                    onClick={handleReply}
                                >
                                    Reply
                                </button>
                            }
                        </div>
                        :
                        <div className="flex flex-col sm:flex-row items-center pr-2 mt-4">
                            <p>Please login to reply!</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CommentsPopup;

function InitialComment(args) {
    const [likeColor, setLikeColor] = useState('text-gray-500');
    const [likeCount, setLikeCount] = useState(args.like ? +args.like : 0);

    const [disloveColor, setDisloveColor] = useState('text-gray-500');
    const [disloveCount, setDisloveCount] = useState(args.dislove ? +args.dislove : 0);

    const handleLikeClick = () => {
        const isRed = likeColor === 'text-red-500';
        const wasDisliked = disloveColor === 'text-blue-500';

        setLikeColor(isRed ? 'text-gray-500' : 'text-red-500');
        setLikeCount(prevCount => isRed ? prevCount - 1 : prevCount + 1);

        if (!isRed && wasDisliked) {
            setDisloveColor('text-gray-500');
            setDisloveCount(prevCount => prevCount - 1);
        }
    };

    const handleDisloveClick = () => {
        const isBlue = disloveColor === 'text-blue-500';
        const wasLiked = likeColor === 'text-red-500';

        setDisloveColor(isBlue ? 'text-gray-500' : 'text-blue-500');
        setDisloveCount(prevCount => isBlue ? prevCount - 1 : prevCount + 1);

        if (!isBlue && wasLiked) {
            setLikeColor('text-gray-500');
            setLikeCount(prevCount => prevCount - 1);
        }
    };

    return (
        <div>
            <div className="flex items-center">
                <div className="w-10 h-10 sm:w-16 sm:h-14 overflow-hidden rounded-full">
                    <img className="w-full h-full object-cover"
                         src={args.avatar} alt="Profile avatar"/>
                </div>
                <div className="flex justify-between w-full pl-6 items-center ml-auto">
                    <h1 className="text-base sm:text-3xl font-bold text-gray-500">{args.name ? args.name : "Anonymous"}</h1>
                    <div className="flex h-1/2 items-center justify-end">
                        <h2 className="text-base sm:text-xl font-semibold text-gray-400 ml-2">{args.posttime ? args.posttime : "Just now"}</h2>
                    </div>
                </div>
            </div>
            <p className="text-gray-600 dark:text-gray-300">{args.desc ? args.desc : "No content"}</p>
            <div className="flex relative">
                <button onClick={handleLikeClick}>
                    <div className="flex pr-5 items-center py-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className={`${likeColor} w-7`}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                            />
                        </svg>

                        <p className={`${likeColor} text-base`}>
                            {likeCount}
                        </p>
                    </div>
                </button>
                <button onClick={handleDisloveClick}>
                    <div className="flex pr-5 items-center py-3">
                        <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke-width="1.5" 
                                stroke="currentColor" 
                                className={`${disloveColor} w-7`}
                            >
                            <path 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54" 
                            />
                        </svg>

                        <p className={`${disloveColor} text-base`}>{disloveCount}</p>
                    </div>
                </button>
                <button>
                    <div className="flex pr-5 items-center py-3">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke-width="1.5" 
                            stroke="currentColor" 
                            className="text-gray-500 w-7"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                        </svg>
                        <p className="text-base text-gray-500">{args.commentCount ? args.commentCount : 0}</p>
                    </div>
                </button>
                <button className="relative">
                    <div className="flex items-center py-3">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke-width="1.5" 
                            stroke="currentColor" 
                            className="text-gray-500 w-7"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                        </svg>
                        <p className="text-base text-gray-500">{args.share ? args.share : 0}</p>
                    </div>
                </button>
            </div>
            <div className="h-[2px] bg-gray-300 w-11/12 mx-auto mt-0 sm:w-full sm:mt-0"/>
        </div>
    );
}

function Replies(args) {
    return (
        <div className="py-1.5">
            <div className="flex justify-between items-center ml-10">
                <div className="w-full flex items-center justify-between">
                    <div className="w-10 h-10 overflow-hidden rounded-full">
                        <img className="w-10 h-10 object-cover"
                            src={args.avatar} alt="Profile avatar"/>
                    </div>
                    <div className="flex justify-between w-full pl-3 items-center ml-auto">
                        <h1 className="text-base sm:text-3xl font-bold text-gray-500">{args.name ? args.name : "Anonymous"}</h1>
                        <div className="flex h-1/2 items-center justify-end">
                            <h2 className="text-base sm:text-xl font-semibold text-gray-400 ml-2">{args.posttime ? args.posttime : "Just now"}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <p className="pl-10 text-gray-500 dark:text-gray-300">{args.desc ? args.desc : "No content"}</p>
            <div className="h-[2px] bg-gray-300 w-11/12 ml-auto mt-0 sm:w-[95%] sm:mt-0"/>
        </div>
    );
}