import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from "../../API";

export const universitiesPageTextAPI = createApi({
    reducerPath: 'universitiesPageTextAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.jwt;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUniversitiesPageText: builder.query({
            query: () => '/universitypage-texts/1',
        }),
    }),
});

export const { useGetUniversitiesPageTextQuery } = universitiesPageTextAPI;
