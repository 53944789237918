import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {API} from "../../API";

export const uploadAPI = createApi({
    reducerPath: 'uploadAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.jwt
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        },
    }),
    endpoints: (builder) => ({
        uploadPicture: builder.mutation({
            query: (formData) => ({
                url: '/upload',
                method: 'POST',
                body: formData,
            }),
        }),
        updateUploadedPicture: builder.mutation({
            query: (uploadData) => ({
                url: `/upload?id=${uploadData.id}`,
                method: 'POST',
                body: uploadData.formData,
            }),
        }),
        getAllUploadedPictures: builder.query({
            query: () => ({
                url: `/upload/files`,
                method: 'GET',
            }),
        }),
        getUploadedPictureByAltText: builder.query({
            query: (altText) => ({
                url: `/upload/files?filters[alternativeText][$eq]=${altText}`,
                method: 'GET',
            }),
        }),
    }),
})

export const {
    useUploadPictureMutation,
    useUpdateUploadedPictureMutation,
    useGetAllUploadedPicturesQuery,
    useGetUploadedPictureByAltTextQuery,
} = uploadAPI
