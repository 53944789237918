import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from "../../API";  

export const homepageAPI = createApi({
    reducerPath: 'homepageAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.jwt;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getHomepageText: builder.query({
            query: () => '/homepage-texts/1',
        }),
    }),
});

export const { useGetHomepageTextQuery } = homepageAPI;
