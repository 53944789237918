import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../API";
import { useEffect, useState } from "react";
// import Star from "../../images/icons/StarYellow.png";
import GradCap from '../../images/icons/graduationCol.png';
import SelectButtonGroup from '../../components/Elements/SelectButtonGroup';
import Grid from '../../images/icons/Grid.png';
import List from '../../images/icons/List.png';
import Star from '../../images/icons/StarGray.png';
import StarYellow from '../../images/icons/StarYellowFill.png';

import PageHeading from "../../components/ProgramsAndSubjectsLoad/PageHeading";

import { useGetUniversitiesPageTextQuery } from "../../app/service/universitiesPageTextAPI";


function Universities() {
    const [universities, setUniversities] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [sortOption, setSortOption] = useState('alphabetical');
    const [displayOption, setDisplayOption] = useState('grid');

    const { data: universitiespageText, isError: isPageTextError, isLoading: isPageTextLoading } = useGetUniversitiesPageTextQuery();

    console.log(isPageTextError)

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sortField = sortOption === 'alphabetical' ? 'universityName:asc' : 'universityRating:desc';
                const response = await fetch(
                    `${BASE_URL}/api/university-pages?populate[universityLogo][fields][0]=alternativeText&populate[universityLogo][fields][1]=formats&sort[0]=${sortField}`
                );
                const responseData = await response.json();

                setUniversities(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [sortOption]);

    const handleClick = (id) => {
        navigate(`/universities/${id}`);
    };

    const handleSearch = async () => {
        try {
            if (searchQuery.trim() !== "") {
                const response = await fetch(
                    `https://backendstudentschoicedev.sithumd.com/api/fuzzy-search/search?query=${searchQuery}`
                );
                const searchData = await response.json();

                const updatedSearchData = await Promise.all(
                    searchData['university-pages'].map(async (item) => {
                        const response = await fetch(
                            `https://backendstudentschoicedev.sithumd.com/api/university-pages/${item.id}?populate[universityLogo][fields][0]=formats`
                        );
                        const bannerData = await response.json();

                        return {
                            ...item,
                            universityLogo: bannerData?.data?.attributes?.universityLogo || null,
                        };
                    })
                );

                setSearchResult(updatedSearchData);
                setShowResults(true);
            }
        } catch (error) {
            console.error('Error searching:', error);
        }
    };

    useEffect(() => {
        if (showResults) {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
    }, [showResults]);

    const sortOptions = [
        { text: "Alphabetical", type: "alphabetical" },
        { text: "Most Popular", type: "mostPopular" }
    ];

    const displayOptions = [
        { image: Grid, type: "grid" },
        { image: List, type: "list" }
    ];

    return (
        <div className="m-0 text-sc-red overflow-hidden bg-white dark:bg-gray-900">
            <PageHeading pageName="Universities" icon={GradCap }/>

            <div className="primaryPageSizing">
                <div className="flex mt-[2.5rem] items-center justify-center sm:justify-between">
                    <div className="flex items-center">
                        <h2 className="hidden sm:block text-left text-3xl font-bold ml-4 text-sc dark:text-gray-300">Sort by</h2>
                        <div className="flex justify-center mx-auto">
                            <SelectButtonGroup
                                options={sortOptions}
                                selectedOption={sortOption}
                                onOptionChange={setSortOption}
                            />
                        </div>
                        
                    </div>
                    <div className="hidden sm:block">
                        <SelectButtonGroup
                            options={displayOptions}
                            selectedOption={displayOption}
                            onOptionChange={setDisplayOption}
                            buttonWidth={50}
                        />
                    </div>
                    
                </div>
                <div className={`relative flex flex-col w-full h-full sm:mt-2 ${displayOption === 'grid' ? 'sm:grid sm:grid-cols-4 2xl:grid-cols-6' : ''}`}>
                    {universities.length === 0 && !isPageTextLoading ? (
                        // Skeleton loading while universities are being fetched
                        [...Array(12)].map((_, index) => (
                            <div key={index} className="animate-pulse h-[14vh] bg-gray-300 rounded-md dark:bg-gray-600 scale-[0.9]" />
                        ))
                    ) : (
                        universities.slice(0, 12).map((university) => (
                            <button
                                className={`relative w-auto ${displayOption === 'list' ? 'h-[10vh]' : 'h-[15vh]'} rounded-md padding-[3%] bg-white dark:bg-gray-600 shadow-md scale-[0.9] sm:hover:shadow-xl transition duration-300`}
                                key={university.id}
                                onClick={() => handleClick(university.id)}
                            >
                                <div className="flex w-full h-full items-center justify-between">
                                    <img
                                        src={BASE_URL + university.attributes.universityLogo.data.attributes.formats.thumbnail.url}
                                        alt={university.attributes.universityLogo.data.attributes.alternativeText}
                                        className={`cursor-pointer object-contain h-full ${displayOption === 'list' ? 'py-2 pl-10' : 'w-full p-5'}`}
                                    />
                                    <p className={`${displayOption === 'list' ? 'block dark:text-gray-200 font-bold pr-10 text-2xl' : 'hidden'}`}>{university.attributes.universityName}</p>
                                </div>
                                
                                <div className="flex absolute bottom-0 right-0 dark:text-gray-200 font-bold items-center gap-1">
                                    <p>
                                        {university.attributes.universityRating === 0
                                            ? "-"
                                            : Number.isInteger(university.attributes.universityRating)
                                            ? university.attributes.universityRating
                                            : university.attributes.universityRating.toFixed(1)}
                                    </p>
                                    <img class="w-4 h-4 hidden dark:block" src={Star} alt="Star"/>
                                    <img class="w-4 h-4 block dark:hidden" src={StarYellow} alt="Star"/>
                                </div>
                            </button>
                        ))
                    )}
                </div>

                {!isPageTextLoading &&
                    <div className="titleTextSecondary">{universitiespageText.data.attributes.searchTitle}</div>
                }

                <div className="flex flex-col sm:flex-row items-center">
                    <input
                        className="editInputStyling"
                        placeholder="Search university name..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                    />
                    <button className="cursor-pointer inline-block bg-rose-900 hover:bg-rose-950 text-white font-bold py-3 px-4 rounded transition duration-300 w-full sm:w-[10%]" onClick={handleSearch}>
                        Search
                    </button>
                </div>
            </div>

            <br />

            {showResults && (
                <div className="relative w-[95%] px-2 pb-1 mx-auto">
                    <div className="text-left text-2xl ml-2 mb-0 text-sc-red dark:text-gray-300 font-bold">Results</div>

                    <ul className={`relative flex flex-col w-full h-full ${displayOption === 'grid' ? 'sm:grid sm:grid-cols-4 2xl:grid-cols-6' : ''}`}>
                        
                        {searchResult.slice(0, 6).map((university) => (
                            <div key={university.id}>
                                <button
                                    className={`w-full ${displayOption === 'list' ? 'h-[10vh]' : 'h-[15vh]'} rounded-md padding-[3%] bg-white dark:bg-gray-600 shadow-md scale-[0.9] sm:hover:shadow-xl transition duration-300`}
                                    onClick={() => handleClick(university.id)}
                                >
                                    <img
                                        src={BASE_URL + university.universityLogo.data.attributes.formats.thumbnail.url}
                                        alt={university.universityName}
                                        className={`cursor-pointer p-5 object-contain h-full w-full`}
                                    />
                                </button>
                            </div>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Universities;