import React, { useState, useEffect } from "react";
import CrossBtnLight from '../../images/icons/CrossLight.png';
import Star from '../../images/icons/StarGray.png';
import StarYellow from '../../images/icons/StarYellowFill.png';
import SelectButtonGroup from "../Elements/SelectButtonGroup";
import {useAddPostMutation} from "../../app/service/addPostAPI";
import {useDispatch, useSelector} from "react-redux";
import {setCredentials} from "../../app/features/authentication/AuthenticationReducer";
import {useGetUserDetailsQuery} from "../../app/service/usersAPI";
import {toggleUIState} from "../../app/features/ui/UIReducer";
import {useGetBlogDataQuery, useGetQnADataQuery, useGetReviewDataQuery} from "../../app/service/any-pagesAPI";

function AddPostPopup() {

    useEffect(() => {
        // Disable background scrolling
        document.body.style.overflow = 'hidden';
        
        // Clean up function to re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const { user } = useSelector((state) => state.auth);
    const { interactionName } = useSelector((state) => state.ui);

    const { refetch: reviewRefetch } = useGetReviewDataQuery(interactionName);
    const { refetch: blogsRefetch} = useGetBlogDataQuery(interactionName);
    const { refetch: qnasRefetch } = useGetQnADataQuery(interactionName);

    const { refetch } = useGetUserDetailsQuery();
    const dispatch = useDispatch()

    const [postTypeSelect, setPostTypeSelect] = useState("reviews");
    const [rating, setRating] = useState(0);


    const handleRating = (rate) => {
        setRating(rate);
    };

    const [addPost, { error: addPostError, isLoading }] = useAddPostMutation();
    const [addingPost, setAddingPost] = useState(false);
    const [error, setError] = useState("");
    // const [addPost, { data, error, isLoading, isSuccess }] = useAddPostMutation();

    const handleAddPost = async (values) => {
        try {
            const result = await addPost(values).unwrap();

            console.log(result);

            reviewRefetch();
            blogsRefetch();
            qnasRefetch();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if a post is already being added
        if (addingPost) {
            return;
        }
    
        setAddingPost(true);
        setError("");
    
        const postText = e.target.elements.postTextInput.value.trim();
        if (!postText) {
            setError("Post cannot be empty or just spaces");
            setAddingPost(false);
            return;
        }
    
        const idInteractionName = interactionName.split("/")[1];
        const postRating = rating || 0;
    
        const getPageType = () => {
            switch (interactionName.split("/")[0]) {
                case "program-pages": return 'program_page';
                case "subject-pages": return 'subject_page';
                default: return 'university_page';
            }
        };
    
        const getPostData = () => {
            const baseData = {
                likes: 0,
                text: postText,
                users_permissions_user: user.id,
            };
    
            switch (postTypeSelect) {
                case "reviews":
                    return {
                        ...baseData,
                        reviewLikes: baseData.likes,
                        reviewText: baseData.text,
                        reviewRating: postRating,
                        [getPageType()]: { id: idInteractionName },
                    };
                case "qnas":
                    return {
                        ...baseData,
                        qnaLikes: baseData.likes,
                        qnaText: baseData.text,
                        [getPageType()]: { id: idInteractionName },
                    };
                default:
                    return {
                        ...baseData,
                        blogLikes: baseData.likes,
                        blogText: baseData.text,
                        [getPageType()]: { id: idInteractionName },
                    };
            }
        };
    
        const newPostData = getPostData();
    
        try {
            await handleAddPost({ newPostData, postTypeSelect });
            const updatedUserProfile = await refetch();
            dispatch(setCredentials(updatedUserProfile.data));
            dispatch(toggleUIState({ key: 'showCreatePost' }));
        } catch (error) {
            console.error(error);
        } finally {
            setAddingPost(false);
    
            // Optional: Cooldown timer to prevent immediate re-posting
            setTimeout(() => {
                setAddingPost(false); // Re-enable the button after the cooldown
            }, 3000); // 3-second cooldown
        }
    };

    const postTypeOptions = [
        { text: "Review", type: "reviews" },
        { text: "Blog", type: "blogs" },
        { text: "Question & Answer", type: "qnas" },
    ];

    return (
        <div>
            <div className="fixed top-0 left-0 m-0 w-screen h-[100dvh] flex justify-center items-center z-10 bg-black bg-opacity-50">
                <div className="popUpStyling">
                    <div className="flex justify-end items-center relative w-full h-[8vh] mt-[3.5rem] sm:mt-0">
                        <img src={CrossBtnLight}
                             className="h-[50px] ml-3 bg-white dark:bg-gray-700 rounded-md shadow-md sm:hover:shadow-xl transition duration-300 cursor-pointer"
                             alt="Cancel button"
                             onClick={() => { dispatch(toggleUIState({ key: 'showCreatePost' }))}}/>
                    </div>

                    <h1 className="text-2xl font-bold text-sc-red dark:text-gray-200 mt-[-3%]">Create post!</h1>

                    <form className="w-4/5 mx-auto flex flex-col items-center justify-around h-full" onSubmit={handleSubmit}>
                        <SelectButtonGroup
                            options={postTypeOptions}
                            selectedOption={postTypeSelect}
                            onOptionChange={setPostTypeSelect}
                        />

                        {postTypeSelect === "reviews" && (
                            <div className="flex h-[10%] items-center justify-center mt-[-3%]">
                                {[1, 2, 3, 4, 5].map((value) => (
                                    <img
                                        key={value}
                                        src={rating >= value ? StarYellow : Star}
                                        alt={`${value} star`}
                                        onClick={() => handleRating(value)}
                                        className="cursor-pointer h-[80%] mx-1"
                                    />
                                ))}
                            </div>
                        )}
                        <div className="flex items-center w-full h-1/3 sm:h-auto mt-[-2%] ">
                                <textarea className="shadow-inner overflow-y-auto w-full h-full sm:h-52 resize-none p-2 rounded-md align-top text-left bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-200" name="postTextInput" type="postTextInput"
                                          placeholder="What do you want to say?" required />
                        </div>

                        <button type="submit"
                                className="bg-white dark:bg-gray-700 border-none shadow-md flex justify-evenly items-center text-sc-red dark:text-gray-200 font-bold cursor-pointer w-1/2 h-[6vh] rounded-md sm:hover:shadow-xl transition duration-300 align-bottom mt-[-3%]"
                                disabled={addingPost}>
                            {isLoading ? "Sending Post..." : "Send Post"}
                        </button>
                        {error && <h4 className="text-gray-500 dark:text-gray-300">{error}</h4>}
                        {addPostError && <h4 className="text-gray-500 dark:text-gray-300">{addPostError}</h4>}

                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddPostPopup;