import InteractionArea from '../../components/Posts/InteractionArea.js';

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BASE_URL } from "../../API";
import '../../input.css';

//For fetching icons
import { useGetIconByIdQuery } from '../../app/service/iconsAPI.js';

// main export
function UniReview() {
    const { idUniversity } = useParams();
    const [university, setUniversity] = useState(null);

    const { data: starIconData, isError: isStarIconError, isLoading: isStarIconLoading } = useGetIconByIdQuery(1); // Fetching the star icon with ID 1

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages/${idUniversity}
                    ?populate[universityLogo][fields][0]=name
                    &populate[universityLogo][fields][1]=alternativeText
                    &populate[universityLogo][fields][2]=formats
                    &populate[universityHeaderImage][fields][0]=name
                    &populate[universityHeaderImage][fields][1]=alternativeText
                    &populate[universityHeaderImage][fields][2]=formats
                    &populate[program_pages][fields][0]=programName
                    &populate[program_pages][fields][1]=programRating
                    &populate[subject_pages][fields][0]=subjectName
                    &populate[subject_pages][fields][1]=subjectRating
                    `);
                const responseData = await response.json();

                setUniversity(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [idUniversity]);

    if (!university) {
        return <div>Loading...</div>;
    }

    if (isStarIconLoading) {
        return null;
    }

    if (isStarIconError) {
        return null;
    }

    return (
        // added dark class
        <div className="w-screen h-screenc relative m-0 bg-white dark:bg-gray-900">
            <div className="relative">
                <UniBanner universityData={university.attributes.universityHeaderImage.data.attributes} />
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '15%' }}>
                    <UniTitleAndImg universityHeader={university.attributes} />
                </div>
            </div>
            <div className="h-[30%] mt-[-3%] relative">
                {!isStarIconLoading && //This is saying "only render the ProgramAndSubject component after starIcon finishes loading"
                    <ProgramAndSubject universityHeader={university} starIconURL={`${BASE_URL}${starIconData.data.attributes.image.data[0].attributes.formats.thumbnail.url}`} //Dynamic URL construction without relying on a specific file name or URL structure. So even if the file name or URL structure changes, the icon file can still be fetched without updating the code.)
                    />
                }
            </div>
            <InteractionArea interactionName={`university-pages/${idUniversity}`} />
        </div>
    );
}

function UniTitleAndImg({ universityHeader }) {
    return (
        <div className="h-full flex justify-between">
            <div className="w-full sm:w-4/5">
                <h1 className="font-bold ml-1 mt-0 text-2xl sm:text-4xl 2xl:text-6xl text-black dark:text-gray-200">{universityHeader.universityName}</h1>
                <p style={{ marginTop: "0%" }} className="ml-1 font-bold text-xl sm:text-2xl 2xl:text-4xl text-gray-500 dark:text-gray-300">{universityHeader.universityLocation}</p>
            </div>

            <img src={BASE_URL + universityHeader.universityLogo.data.attributes.formats.thumbnail.url}
                className="hidden sm:block h-4/5 mr-[2%] self-center" alt={universityHeader.universityLogo.data.attributes.alternativeText} />
        </div>
    );
}

function ProgramAndSubject({ universityHeader, starIconURL }) {
    const sortedPrograms = universityHeader.attributes.program_pages.data.sort((a, b) => {
        const ratingA = a.attributes.programRating;
        const ratingB = b.attributes.programRating;

        if (ratingA === null) return 1;
        if (ratingB === null) return -1;

        return ratingB - ratingA;
    });

    const sortedSubjects = universityHeader.attributes.subject_pages.data.sort((a, b) => {
        const ratingA = a.attributes.subjectRating;
        const ratingB = b.attributes.subjectRating;

        if (ratingA === null) return 1;
        if (ratingB === null) return -1;

        return ratingB - ratingA;
    });

    return (
        <div className="h-full w-full mt-10">
            {universityHeader.attributes.program_pages.data.length > 0 && (
                <>
                    <h3 className="text-sc-red dark:text-gray-200 ml-6 sm:ml-12 2xl:ml-24 text-3xl font-bold sm:mb-1">Programs</h3>
                    <div className="sm:flex sm:justify-between w-11/12 m-auto">
                        {sortedPrograms.slice(0, 3).map((university) => (
                            <Link style={{ width: "32.5%" }}
                                to={`/universities/${universityHeader.id}/program/${university.id}`}>
                                <ShadowBox
                                    name={university.attributes.programName}
                                    rating={university.attributes.programRating !== null
                                        ? university.attributes.programRating
                                        : "-"}
                                    starIconURL={starIconURL} // Pass the dynamic star icon URL
                                />
                            </Link>
                        ))}
                    </div>
                </>
            )}

            {universityHeader.attributes.subject_pages.data.length > 0 && (
                <>
                    <h3 className="text-sc-red dark:text-gray-200 ml-6 sm:ml-12 2xl:ml-24 text-3xl mt-4 font-bold sm:mb-1">Subjects</h3>
                    <div className="sm:flex sm:justify-between w-11/12 m-auto">
                        {sortedSubjects.slice(0, 3).map((university) => (
                            <Link style={{ width: "32.5%" }}
                                to={`/universities/${universityHeader.id}/subject/${university.id}`}>
                                <ShadowBox
                                    name={university.attributes.subjectName}
                                    rating={university.attributes.subjectRating !== null
                                        ? university.attributes.subjectRating
                                        : "-"}
                                    starIconURL={starIconURL} // Pass the dynamic star icon URL
                                />
                            </Link>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

function ShadowBox(args) {
    const { name, rating, starIconURL } = args;
    return (
        <div className="relative flex justify-center items-center h-16 w-full bg-white dark:bg-gray-600 drop-shadow-md rounded-md m-auto mb-4 sm:mb-0 sm:hover:drop-shadow-xl transition duration-300">
            <p className="text-gray-600 dark:text-gray-200 text-lg sm:text-xl px-10 sm:px-2 text-center font-bold">{name}</p>

            <div className="absolute flex items-center h-1/2 bottom-0 right-0">
                <p className="text-base text-gray-600 dark:text-gray-300 mr-1 font-bold">{rating}</p>
                {starIconURL ? (
                    <img src={starIconURL} className="h-3/5 mr-1" alt="Yellow star icon" />
                ) : (
                    <div className="h-3/5 mr-1" /> // Placeholder or loader if icon URL is not available
                )}
            </div>
        </div>
    )
}

function UniBanner({ universityData }) {
    return (
        <div style={{ height: '60%', position: 'relative' }}>
            <div className="absolute top-0 left-0 w-full h-[90%] sm:h-[70%] bg-gradient-to-b from-white dark:from-gray-800 to-transparent"></div>
            <img src={BASE_URL + universityData.formats.large.url}
                className="w-screen block object-cover h-[30vh] sm:h-[50vh]" alt={universityData.alternativeText} />
            <div className="bg-gradient-to-t from-white dark:from-gray-900 to-transparent w-full h-[20%] absolute bottom-0 left-0">
            </div>
        </div>
    );
}

export default UniReview;
