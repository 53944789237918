import PageHeading from "../../components/ProgramsAndSubjectsLoad/PageHeading";
import Privacy from "../../images/icons/privacy.png";

function PrivacyPolicy() {

    return (
        <>
            <PageHeading pageName="Privacy Policy" icon={Privacy}/>
            <p className="ml-10 text-xl">Content goes here</p>
        </>
    )
}

export default PrivacyPolicy;